@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');


body {
  width: auto;
}

h2 {
  font-size: 40px !important;
  font-weight: 700 !important;
}

h1 {
  font-size: 40px !important;
  font-weight: 700 !important;
}


* {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat', sans-serif;
}

.blurImage {
  filter: blur(15px);
}

.nav_ul li {
  padding: 0 0.6rem;
}

.nav_ul a {
  font-size: 1.4rem;
  color: #CE0932 !important;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.btn1 {
  border: 0;
  padding: 0.5rem 1.8rem;
  color: white;
  background-color: #CE0932;
  cursor: pointer;
}

.learnMore {
  width: 70%;
  border: 0;
  padding: 0.5rem 1.8rem;
  color: white;
  background-color: #CE0932;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learnMore:hover {
  background-color: #D32F53;
}

.learnMore2 {
  width: 90%;
  border: 0;
  padding: 0.5rem 1.8rem;
  color: white;
  background-color: #CE0932;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10%;
}

.learnMore2:hover {
  background-color: #D32F53;
}

.btn2 {
  border: 0;
  padding: 0.5rem 1.2rem;
  color: white;
  background-color: #CE0932;
  cursor: pointer;
}

.btn3 {
  color: white;
  background-color: #CE0932;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.mission-section {
  background-color: #f8f9fa;
  padding: 3rem 1rem;
}



.mission-section h2 {
  color: #CE0932;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.mission-section p {
  font-size: 1.2rem;
  color: #212529;
  font-weight: 500;
}

.mission-heading {
  position: relative;
  display: inline-block;
  color: #CE0932;
}

.mission-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 255, 0);
  transform-origin: bottom left;
  animation: expandUnderline 1s ease-in-out infinite alternate;
}

.mission-heading2 {
  position: relative;
  display: inline-block;
  color: #CE0932;
}

.mission-heading2 {
  position: relative;
  margin: 20px;
}

.mission-heading2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 2px;
  background-color: white;
  transform-origin: bottom left;
}

.mission-heading3-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
}

.mission-heading3 {
  position: relative;
  display: inline-block; 
  color: #CE0932;
  margin: 20px;
}

.mission-heading3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgb(255, 255, 0);
  transform-origin: bottom left;
  animation: expandUnderline 1s ease-in-out infinite alternate;
}


@keyframes expandUnderline {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

.carousel-item img {
  animation: zoom 10s infinite;
}


.counter-wrapper {
  margin-top: -40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1.5rem;
  padding: 10rem 9%;
  position: relative;
}

.counter-wrapper::before {
  background-color: #CE0932;
  position: absolute;
  content: '';
  content: 0;
  top: 112px;
  left: 0;
  width: 100%;
  height: 60%;
  z-index: 1;
}

.counter {
  text-align: center;
  color: white;
  z-index: 2;
  position: relative;
}

.counter::before {
  position: absolute;
  content: '';
  left: 50%;
  width: 20%;
  height: .2rem;
}

.counter .count {
  font-size: 5rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.card-color-area {
  width: 100%;
  height: 50px;
  border-radius: 0 0 35px 35px;
  position: absolute;
  margin-top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
}

.counter p {
  font-size: 1.4rem;
  font-weight: 700;
}

.card-img {
  height: 330px;
  width: 100px;
  object-fit: contain;
}

.card-title {
  font-weight: 700 !important;
  text-align: left;
}

.card-list {
  color: #212529;
  font-weight: 600;
  text-align: left !important;
  padding-left: 5px;
  list-style: none;

}

.card-list li:before {
  content: '->';
  margin-right: 8px;
  color: #212529;
}

.card-list li {
  padding: 6px;
}


.card-list3 {
  color: #212529;
  font-weight: 600;
  text-align: left !important;
  padding-left: 5px;
  list-style: none;

}

.card-list3 li:before {
  content: '->';
  margin-right: 12px;
  color: #212529;
}

.card-list3 li {
  padding: 6px;
}


.card-list2 {
  color: white;
  font-weight: 600;
  text-align: left !important;
  padding-left: 5px;
  font-size: 0.8rem;
  list-style: none;
}

.card-list2 li:before {
  content: '✓';
  margin-right: 12px;
  color: white;
}

.card-list2 li {
  padding: 10px;
}

/* .group {
  position: relative;
  cursor: pointer;
  transition: shadow 0.3s ease;
}

.group:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.group .absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.group .bg-gradient-to-b {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
}

.group:hover .bg-gradient-to-b {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0.7) 70%);
}

.group .translate-y {
  transform: translateY(60%);
}

.group:hover .translate-y {
  transform: translateY(0);
}

.group .card-list {
  position: relative;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.group .card-list li {
  list-style-type: none;
  margin-bottom: 5px;
  margin-top: 10px;
}

.group:hover .card-list {
  opacity: 1;
}


.group:hover .card-content {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.group:hover .card-content {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
}

.group .card-title {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
} */


.team-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.team-card-img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.team-card-body {
  flex: 1;
  background-color: #f8f9fa;
}

.team-card-title {
  color: #CE0932;
  font-weight: 700;
}

.card-list {
  color: #212529;
}

.slider-container {
  padding: 20px;
  background-color: #f8f9fa;
}

.carousel-container {
  padding: 20px 0;
}

.carousel-item-wrapper {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.academy-card {
  width: 100%;
  max-width: 600px;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: row;
  height: 45vh !important;
}

.academy-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.allCards {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.allCards:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pillarCard {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.pillarCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image-horizontal {
  width: 150px;
  height: auto;
  object-fit: cover;
}

.card-body-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  flex: 1;
}

.card-title {
  font-size: 1.5rem;
  color: #333;
}

.card-text {
  font-size: 1rem;
  color: #666;
  margin: 15px 0;
  flex: 1;
}

.card-button {
  background-color: #CE0932;
  border: none;
  transition: background-color 0.3s;
  align-self: flex-start;
}

.card-button:hover {
  background-color: #e04b3a;
}

.imageBox {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-title {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 23px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  font-size: 24px;
  white-space: normal;
  line-height: 28px;
}

.wrapper {
  padding: 0px 10%;
}

/* .box {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  height: 60vh;
  width: 100%;
}

.box img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;
  object-fit: cover;
  transition: transform 0.5s;
}

.overlay {
  width: 100%;
  height: 0;
  background: linear-gradient(transparent, #1c1c1c 68%);
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: height 0.9s;
}

.overlay h3 {
  font-weight: 500;
  color: white;
  margin-bottom: 5px;
  margin: 10% 0;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2px
}

.overlay li {
  text-indent: -26px;
  font-size: 18px;
  cursor: pointer;
}


.box:hover img {
  transform: scale(1.1);
}

.box:hover .overlay {
  height: 100%;
}

.overlay-container {
  position: relative;
}

.overlay-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
} */




.circle-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 20px;
  margin-top: 42px;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.central-circle {
  width: 200px;
  height: 170px;
  background-color: #d40303;
  color: white;
  border-radius: 50%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.surrounding-circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.surrounding-circle {
  width: 180px;
  height: 120px;
  font-weight: 500;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.surrounding-circle:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.surrounding-circle:nth-child(1) {
  background-color: #ffa500;
}

.surrounding-circle:nth-child(2) {
  background-color: #FF8C00;
}

.surrounding-circle:nth-child(3) {
  background-color: #E26310;
}

.surrounding-circle:nth-child(4) {
  background-color: #F5761A;
}

.surrounding-circle:nth-child(5) {
  background-color: #FFA836;
}

.surrounding-circle:nth-child(6) {
  background-color: #FF4500;
}

.surrounding-circle:nth-child(7) {
  background-color: #FF7400;
}

@media (max-width: 1200px) {
  .circle-container {
    margin-left: 230px;
  }

  .surrounding-circle {
    width: 130px;
    height: 120px;
  }
}

@media (max-width: 992px) {
  .circle-container {
    margin-left: 230px;
  }

  .surrounding-circle {
    width: 130px;
    height: 120px;
  }
}

@media (max-width: 768px) {
  .circle-container {
    margin-left: 100px;
  }

  .surrounding-circle {
    width: 130px;
    height: 120px;
  }
}

@media (max-width: 576px) {
  .circle-container {
    margin-left: 230px;
  }

  .surrounding-circle {
    width: 130px;
    height: 120px;
  }
}





/* FINANC */


.circle-container2 {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 20px;
  margin-top: 42px;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.central-circle2 {
  width: 200px;
  height: 170px;
  background-color: #d40303;
  color: white;
  border-radius: 50%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.surrounding-circles2 {
  position: relative;
  width: 100%;
  height: 100%;
}

.surrounding-circle2 {
  width: 180px;
  height: 120px;
  font-weight: 500;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.surrounding-circle2:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.surrounding-circle2:nth-child(1) {
  background-color: #ffa500;
}

.surrounding-circle2:nth-child(2) {
  background-color: #FF8C00;
}

.surrounding-circle2:nth-child(3) {
  background-color: #E26310;
}

.surrounding-circle2:nth-child(4) {
  background-color: #F5761A;
}

.surrounding-circle2:nth-child(5) {
  background-color: #FFA836;
}

.surrounding-circle2:nth-child(6) {
  background-color: #FF4500;
}

.surrounding-circle2:nth-child(7) {
  background-color: #FF7400;
}

@media (max-width: 1200px) {
  .circle-container2 {
    margin-left: 230px;
  }

  .surrounding-circle2 {
    width: 130px;
    height: 120px;
  }
}

@media (max-width: 992px) {
  .circle-container2 {
    margin-left: 180px;
  }

  .surrounding-circle2 {
    width: 120px;
    height: 100px;
  }
}

@media (max-width: 768px) {
  .circle-container2 {
    margin-left: 190px;
  }

  .surrounding-circle2 {
    width: 130px;
    height: 120px;
  }
}

@media (max-width: 576px) {
  .circle-container2 {
    margin-left: 50px;
  }

  .surrounding-circle2 {
    width: 120px;
    height: 120px;
    margin-left: 10PX;
  }

  .central-circle2 {
    width: 200px;
    height: 170px;
    background-color: #d40303;
    color: white;
    border-radius: 50%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}



/* BannerImage.css */
.image-container {
  position: relative;
  width: 100%;
  height: 40vh;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(102, 3, 3, 0.75);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}



.image-container2 {
  position: relative;
  width: 100%;
  height: 120vh;
}

.banner-image2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(102, 3, 3, 0.75);
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.overlay2 h1 {
  margin: 50px 0;
  color: white;
  width: 100%;
  text-align: center;
}

.box2 {
  width: 650px;
  height: 250px;
  margin-top: 20px;
  border: 2px solid white;
  position: relative;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  60% {
    transform: translateY(5px);
  }
}

.central-circle2 {
  width: 200px;
  height: 170px;
  background-color: #d40303;
  color: white;
  border-radius: 50%;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer !important;
  transform: translate(-50%, -50%);
}


.arrow-container {
  position: absolute;
  top: calc(50% + 20px);
  left: 50%;
  transform: translateX(-50%);
}

.subscription-box {
  background-color: #c30932;
  color: white;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: radial-gradient(circle at center, #c30932, #c30932, #c30932, #c30932, #7f041d);
}

.subscription-box .left {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subscription-box .right {
  flex: 1;
}

.subscription-box form {
  margin-top: 20px;
}

.input-field,
.phone-input {
  padding: 10px;
  border: none;
  font-size: 1em;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 45px;
}

.phone-input-container .flag-dropdown {
  border: none !important;
}

.phone-input-container .phone-input {
  padding-left: 45px !important;
}

.subscription-box button.submit-button {
  background-color: #f15a24;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

.subscription-box button.submit-button:hover {
  background-color: #d0411b;
}

.subscribe-now {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 10;
}

.subscribe-button {
  background-color: #f15a24;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

.subscribe-button:hover {
  background-color: #d0411b;
}

.partner-box {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.partner-box img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.partner-box p {
  margin-top: 10px;
  flex-grow: 1;
}



.crousalText {
  margin-top: 20px;
  color: white;
  text-align: left;
  font-size: 45px;
  margin-left: 0;
  text-shadow: 4px 3px 4px rgba(0, 0, 0, 0.9);
}

.crousalBox {
  margin-bottom: 110px;
  color: white;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.9);
  margin-right: 80px;
  margin-left: -30px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 20px;
  border-radius: 10px;
}

/* Media query for screens between 0px and 320px (very small screens, e.g., watches) */
@media (min-width: 0px) and (max-width: 320px) {
  .crousalText {
    font-size: 20px;
    text-align: center;
    /* Example adjustment for very small screens */
  }
}

/* Media query for screens between 321px and 576px (very small screens) */
@media (min-width: 321px) and (max-width: 576px) {
  .crousalText {
    font-size: 25px;
    text-align: center;
    /* Further adjust font size for even smaller screens */
  }
}

/* Media query for screens between 577px and 768px (small devices) */
@media (min-width: 577px) and (max-width: 768px) {
  .crousalText {
    font-size: 35px;
    /* Adjust font size for smaller screens */
  }
}


/* Media query for screens between 0px and 320px (very small screens, e.g., watches) */
@media (min-width: 0px) and (max-width: 320px) {
  .crousalBox {
    margin-bottom: 40px;
    /* Example adjustment for very small screens */
    padding: 5px;
    /* Example adjustment for very small screens */
  }
}

/* Media query for screens between 321px and 576px (very small screens) */
@media (min-width: 321px) and (max-width: 576px) {
  .crousalBox {
    margin-bottom: 60px;
    /* Further adjust margin for very small screens */
    margin-right: 20px;
    /* Further adjust margin for very small screens */
    margin-left: -10px;
    /* Further adjust margin for very small screens */
    padding: 10px;
    /* Further adjust padding for very small screens */
  }
}

/* Media query for screens between 577px and 768px (small devices) */
@media (min-width: 577px) and (max-width: 768px) {
  .crousalBox {
    margin-bottom: 80px;
    /* Adjust margin for smaller screens */
    margin-right: 40px;
    /* Adjust margin for smaller screens */
    margin-left: -20px;
    /* Adjust margin for smaller screens */
    padding: 15px;
    /* Adjust padding for smaller screens */
  }
}

@media (max-width: 576px) {
  .allCards {
    padding-bottom: 50px;
    /* Adjust margin for smaller screens */
  }
}

/* Medium devices (tablets, min-width: 576px and max-width: 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .allCards {
    min-height: 40vh;
    max-height: 80vh;
  }
}

.pillarCardHeight {
  width: 20rem;
  border-radius: 15px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (min-width: 0px) and (max-width: 576px) {
  .pillarCardHeight {
    min-height: 40vh;
    max-height: 80vh;
  }
}

/* Medium devices (tablets, min-width: 576px and max-width: 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .pillarCardHeight {
    min-height: 60vh;
    max-height: 70vh;
  }
}

/* Large devices (desktops, min-width: 768px and max-width: 992px) */
@media (min-width: 768px) and (max-width: 992px) {
  .pillarCardHeight {
    min-height: 60vh;
    max-height: 60vh;
  }
}

/* Extra large devices (large desktops, min-width: 992px and max-width: 1200px) */
@media (min-width: 992px) and (max-width: 1200px) {
  .pillarCardHeight {
    min-height: 80vh;
    max-height: 90vh;
  }
}




@media (min-width: 0px) and (max-width: 576px) {
  .mission-section2 {
    display: none;
    /* Hide RedBarSection on mobiles and tablets */
  }
}

/* Medium devices (tablets, min-width: 576px and max-width: 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .mission-section2 {
    display: none;
    /* Hide RedBarSection on mobiles and tablets */
  }
}

/* Large devices (desktops, min-width: 768px and max-width: 992px) */
@media (min-width: 768px) and (max-width: 992px) {
  .mission-section2 {
    display: none;
    /* Hide RedBarSection on mobiles and tablets */
  }
}

/* Extra large devices (large desktops, min-width: 992px and max-width: 1200px) */
@media (min-width: 992px) and (max-width: 1200px) {
  .mission-section2 {
    display: block;
    /* Hide RedBarSection on mobiles and tablets */
  }
}



.slider-container {
  width: 100%;
  padding: 20px 0;
}

.carousel-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.academy-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
}

.card-image-horizontal {
  width: 40%;
  object-fit: cover;
}

.card-body-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.slider-title {
  text-align: center;
  color: #CE0932 !important;
  font-size: 1.6rem;
}

.slider-text {
  text-align: center;
  color: #212529;
  font-size: 1.2rem;
}

.learnMore {
  background-color: #CE0932;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
}

.learnMore:hover {
  background-color: #a30b27;
}

@media (max-width: 1024px) {
  .card-image-horizontal {
    width: 50%;
  }

  .slider-title {
    font-size: 1.8rem;
  }

  .slider-text {
    font-size: 1.4rem;
  }

  .learnMore {
    font-size: 1.2rem;
  }
}

@media (max-width: 464px) {
  .card-image-horizontal {
    width: 50%;
  }

  .slider-title {
    font-size: 2rem;
  }

  .slider-text {
    font-size: 1.6rem;
  }

  .learnMore {
    font-size: 1.2rem;
    width: auto;
    white-space: nowrap;
  }
}




.cardTitle {
  flex: 1;
}

.card-list {
  list-style-type: none;
  padding: 0;
}

.card-list li {
  margin-bottom: 10px;
}

.card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mt-auto {
  margin-top: auto;
}

.missionCardImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 40vh;
}


@media (min-width: 0px) and (max-width: 576px) {
  .missionCardImg {
    height: 30vh;
  }
}

/* Medium devices (tablets, min-width: 576px and max-width: 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .missionCardImg {
    height: 40vh;
  }
}

/* Large devices (desktops, min-width: 768px and max-width: 992px) */
@media (min-width: 768px) and (max-width: 992px) {
  .missionCardImg {
    height: 20vh;
  }
}

/* Extra large devices (large desktops, min-width: 992px and max-width: 1200px) */
@media (min-width: 992px) and (max-width: 1200px) {
  .missionCardImg {
    height: 40vh;
  }
}

.slider-container.center-items .carousel-container {
  display: flex;
  justify-content: center;
}

.slider-container.center-items .carousel-item-wrapper {
  max-width: 100%;
  flex: 0 0 50%;
  box-sizing: border-box;
}